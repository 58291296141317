<template>
  <BaseList
    :list-query="list"
    :completions-query="completions"
    route="source"
    locale-section="pages.sources"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "SourcesList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query sources {
          list: sources {
            id
            name: title
            title
            author
            year
          }
        }
      `,
      completions: gql`
        query findSourceByTitle($search: String!) {
          completions: findSourceByTitle(title: $search) {
            name: title
            id
          }
        }
      `
    };
  }
};
</script>
